/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Table } from "reactstrap"

function CompanyOverview() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            companyName
            companyNameEn
            businessOwners {
              position
              fullName
            }
            establishmentDate
            capital
            postCode
            address
          }
        }
      }
    `
  )
  const companyInfo = site.siteMetadata

  return (
    <Table className="company-info">
      <tbody className="text-center">
        <tr><th>名称</th><td>{companyInfo.companyName}</td></tr>
        <tr><th>英語表記名称</th><td>{companyInfo.companyNameEn}</td></tr>
        <tr>
          <th className="align-middle">代表者</th>
          <td>{companyInfo.businessOwners.map((owner, index) =>
            index === companyInfo.businessOwners.length - 1 ?
              <span key={index}>{owner.position} {owner.fullName}</span> :
              <span key={index}>{owner.position} {owner.fullName}<br /></span>
          )}</td>
        </tr>
        <tr><th>設立日</th><td>{companyInfo.establishmentDate}</td></tr>
        <tr><th>資本金</th><td>{companyInfo.capital}</td></tr>
        <tr><th className="align-middle">所在地</th><td>〒{companyInfo.postCode}<br />{companyInfo.address}</td></tr>
      </tbody>
    </Table>
  )
}

CompanyOverview.defaultProps = {
}

CompanyOverview.propTypes = {
}

export default CompanyOverview
