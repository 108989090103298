import React from "react"
import { Container, Row, Col } from 'reactstrap'

import Link from '../components/link'
// import Button from '../components/btn'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaSchool, FaDev, FaHome, /*FaServer*/ } from 'react-icons/fa'
import Form from '../components/form'
import Slider from '../components/slider'
import Box from '../components/box'
// import Hr from '../components/hr'
// import Benefits from '../components/benefits'
import styled from 'styled-components'
import CompanyOverview from "../components/company"
// import HomeFeatures from '../components/homeFeatures'

let StyledBackground = styled.div`
  background: linear-gradient(to bottom,#f9fbfd 0,#fff 100%);
`

let Service = ({ title, to = '/', Icon = FaHome }) => (
  <Col xs={12} md={6} className="mb-3">
    <Link to={to}>
      <Box>
        <Icon size={30} />
        <h4 className="mt-3">{title}</h4>
      </Box>
    </Link>
  </Col>
)

let Index = () => (
  <Layout>
    <SEO title="Home" lang="ja" />
    <Slider />
    {/* <Container className="pt-4">
      <div className="text-center">
        <h4>A slightly opinionated Gatsby starter template.</h4>
        <p className="text-muted">Built with love on the Gold Coast, QLD Australia</p>
      </div>
    </Container> */}
    <Container className="py-5">
      <h2 className="text-center mb-4">Services</h2>
      <Row>
        <Service title="プログラミングスクール" to="/services/programming-school" Icon={FaSchool} />
        <Service title="受託開発" to="/services/entrusted-development" Icon={FaDev} />
        {/* <Service title="システム運用" to="/services/system-operation" Icon={FaServer} /> */}
      </Row>
    </Container>
    <Container className="py-5">
      <h2 className="text-center mb-4">Company</h2>
      <Row>
        <CompanyOverview />
      </Row>
    </Container>
    {/* <div className="text-center py-5">
      <Button to="https://github.com/jeremylynch/gatsby-strapi-starter" className="btn btn-primary btn-lg">
        <FaGithub className="mr-1" />
        View on Github
      </Button>
    </div> */}
    <StyledBackground>
      {/* <Benefits /> */}
      <div className="py-5">
        <Container>
          <h2 className="text-center mb-4">Contact</h2>
          <Row className="d-flex justify-content-center">
            <Col md={9}>
              <Box style={{ textAlign: 'left' }}>
                <Form />
              </Box>
            </Col>
          </Row>
        </Container>
      </div>
    </StyledBackground>
    {/* <HomeFeatures /> */}
  </Layout>
)

export default Index
